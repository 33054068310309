import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config } from '../config';
import { CollectionPlan, CollectionModel, ItemModel, Contract, User, ContractEvent, NoticeInfo, Document, FileInfo, NoticeRecord, PlanModel, ProjectModel, LoginInfo } from '../models';
import clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
    constructor(private http: HttpClient) {}


    /**
     * 取得指定唯一識別碼的合約
     *
     * @param id 唯一識別碼
     */
    get(
        id: string
        ): Observable<Contract> {
        let url = '/api/Contract/{id}';

        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<Contract>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 取得指定時段內的合約列表
     *
     */
    getList(        ): Observable<Contract[]> {
        let url = '/api/Contract';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<Contract[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 更新合約
     *
     * @param contract 合約
     */
    update(
        contract: Contract
        ): Observable<Contract> {
        let url = '/api/Contract';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.put<Contract>(
            url,
			
            contract
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 新增合約
     *
     * @param contract 合約
     */
    create(
        contract: Contract
        ): Observable<Contract> {
        let url = '/api/Contract';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<Contract>(
            url,
			
            contract
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 刪除合約
     *
     * @param id 唯一識別碼
     */
    delete(
        id?: string
        ): Observable<any> {
        let url = '/api/Contract';
        const queryList = [];

        if (id !== null && id !== undefined) {
            queryList.push('id=' + encodeURIComponent(id.toString()));
        }
            window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.delete<any>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 
     *
     * @param id 
     */
    export(
        id: string
        ): Observable<Blob> {
        let url = '/api/Contract/{id}/export';

        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<Blob>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 取得指定時段內的合約事件列表
     *
     * @param startTime 起始時間
     * @param endTime 結束時間
     */
    getAllEvents(
        startTime?: string,

        endTime?: string
        ): Observable<ContractEvent[]> {
        let url = '/api/Contract/events';
        const queryList = [];

        if (startTime !== null && startTime !== undefined) {
            queryList.push('startTime=' + encodeURIComponent(startTime.toString()));
        }
    
        if (endTime !== null && endTime !== undefined) {
            queryList.push('endTime=' + encodeURIComponent(endTime.toString()));
        }
            window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<ContractEvent[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 電子報通知
     *
     * @param id 唯一識別碼
     * @param info 詳細資訊
     */
    notice(
        id: string,

        info: NoticeInfo
        ): Observable<Blob> {
        let url = '/api/Contract/events/{id}/notice';

        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<Blob>(
            url,
			
            info
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 簡訊通知
     *
     * @param id 唯一識別碼
     * @param info 詳細資訊
     */
    noticeSMS(
        id: string,

        info: NoticeInfo
        ): Observable<Blob> {
        let url = '/api/Contract/events/{id}/notice-sms';

        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<Blob>(
            url,
			
            info
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 取得指定唯一識別碼的合約事件
     *
     * @param contractId 合約的唯一識別
     * @param id 唯一識別碼
     */
    getEvent(
        contractId: string,

        id: string
        ): Observable<ContractEvent> {
        let url = '/api/Contract/{contractId}/events/{id}';

        url = url.replace('{contractId}', (contractId).toString());
    
        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<ContractEvent>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 刪除合約事件
     *
     * @param contractId 合約唯一識別
     * @param id 唯一識別碼
     */
    deleteEvent(
        contractId: string,

        id: string
        ): Observable<any> {
        let url = '/api/Contract/{contractId}/events/{id}';

        url = url.replace('{contractId}', (contractId).toString());
    
        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.delete<any>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 合約事件列表
     *
     * @param contractId 合約的唯一識別
     */
    getEvents(
        contractId: string
        ): Observable<ContractEvent[]> {
        let url = '/api/Contract/{contractId}/events';

        url = url.replace('{contractId}', (contractId).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<ContractEvent[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 更新合約事件
     *
     * @param contractId 合約唯一識別
     * @param entity 合約事件
     */
    updateEvent(
        contractId: string,

        entity: ContractEvent
        ): Observable<ContractEvent> {
        let url = '/api/Contract/{contractId}/events';

        url = url.replace('{contractId}', (contractId).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.put<ContractEvent>(
            url,
			
            entity
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 新增合約事件
     *
     * @param contractId 合約唯一識別
     * @param entity 合約事件
     */
    createEvent(
        contractId: string,

        entity: ContractEvent
        ): Observable<ContractEvent> {
        let url = '/api/Contract/{contractId}/events';

        url = url.replace('{contractId}', (contractId).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<ContractEvent>(
            url,
			
            entity
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    }