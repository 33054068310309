import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { Config } from './config';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /** 取得列表分頁結果 */
  getCompanyList(
    categoryId,
    enable?,
    keyword?,
    offset?,
    limit?,
  ): Observable<any> {
    const url = this.commonService.getQuery('/api/DataManage/Company', [
      { property: 'categoryId', value: categoryId },
      { property: 'enable', value: enable },
      { property: 'keyword', value: keyword },
      { property: 'offset', value: offset },
      { property: 'limit', value: limit },
    ]);

    return this.http.get(url, Config.defaultOptions);
  }

  /** 取得指定實例 */
  getCompany(id): Observable<any> {
    return this.http.get(
      `/api/DataManage/Company/${id}`,
      Config.defaultOptions,
    );
  }

  /** 建立實例 */
  create(data): Observable<any> {
    return this.http.post(
      '/api/DataManage/Company',
      data,
      Config.defaultOptions,
    );
  }

  /** 更新實例 */
  update(data): Observable<any> {
    return this.http.put(
      `/api/DataManage/Company/${data.id}`,
      data,
      Config.defaultOptions,
    );
  }

  /** 刪除指定實例 */
  delete(id) {
    return this.http.delete(
      `/api/DataManage/Company/${id}`,
      Config.defaultOptions,
    );
  }
}

@Injectable()
export class GetCompanyListResolver implements Resolve<any> {
  constructor(private service: CompanyService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const categoryId = route.params.categoryId;
    return this.service.getCompanyList(categoryId);
  }
}

@Injectable()
export class GetCompanyResolver implements Resolve<any> {
  constructor(private service: CompanyService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params.id;
    return this.service.getCompany(id);
  }
}
