import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output() search = new EventEmitter();

  statusArray = [
    { value: null, name: '全部' },
    { value: true, name: '啟用' },
    { value: false, name: '停用' },
  ];

  result = {
    keyword: '',
    enable: null,
  };

  constructor() {}

  ngOnInit() {}

  click() {
    this.search.emit(this.result);
  }
}
