import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import {
    CollectionService,
    ContractService,
    DocumentService,
    FileService,
    NoticeRecordService,
    PlanService,
    UserService
} from './services';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    CollectionService,
    ContractService,
    DocumentService,
    FileService,
    NoticeRecordService,
    PlanService,
    UserService
  ]
})
export class FuneralModule {

}