import { CollectionPlan } from './collectionPlan';

import { CollectionModel } from './collectionModel';

import { ItemModel } from './itemModel';

import { Contract } from './contract';

import { User } from './user';

import { ContractEvent } from './contractEvent';

import { NoticeInfo } from './noticeInfo';

import { Document } from './document';

import { FileInfo } from './fileInfo';

import { NoticeRecord } from './noticeRecord';

import { PlanModel } from './planModel';

import { ProjectModel } from './projectModel';
export class LoginInfo {

    /**
     * 帳號
     */
    account? : string;

    /**
     * 密碼
     */
    password? : string;
}