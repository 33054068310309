import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileService, Config } from 'src/app/api-sdk';

@Component({
  selector: 'app-program-editor',
  templateUrl: './program-editor.component.html',
  styleUrls: ['./program-editor.component.scss'],
})
export class ProgramEditorComponent implements OnInit {
  @Input() list = [];
  fileList = [];
  isShow = false;
  selectedItem = null;

  @Output() update = new EventEmitter();

  constructor(
    private fileService: FileService
  ) { }

  ngOnInit() { }

  keyUp($event, item) {
    item.description = $event.target.value;
  }

  chooseImage(item) {
    this.fileService
      .listFiles()
      .subscribe(res => {
        this.selectedItem = item;
        this.fileList = res;
        this.isShow = true;
      });
  }

  closePopup($event) {
    if ($event) {
      this.selectedItem.picture = $event.url;
    }
    this.isShow = false;
  }

  submit() {
    this.update.emit();
  }
}
