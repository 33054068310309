import { CollectionPlan } from './collectionPlan';

import { CollectionModel } from './collectionModel';

import { ItemModel } from './itemModel';

import { Contract } from './contract';

import { User } from './user';

import { ContractEvent } from './contractEvent';

import { NoticeInfo } from './noticeInfo';

import { Document } from './document';

import { NoticeRecord } from './noticeRecord';

import { PlanModel } from './planModel';

import { ProjectModel } from './projectModel';

import { LoginInfo } from './loginInfo';
export class FileInfo {

    /**
     * 
     */
    fileName? : string;

    /**
     * 
     */
    url? : string;
}