import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config } from '../config';
import { CollectionPlan, CollectionModel, ItemModel, Contract, User, ContractEvent, NoticeInfo, Document, FileInfo, NoticeRecord, PlanModel, ProjectModel, LoginInfo } from '../models';
import clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {
    constructor(private http: HttpClient) {}


    /**
     * 取得所有自訂方案項目
     *
     */
    listCollectio(        ): Observable<CollectionPlan[]> {
        let url = '/api/Collection';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<CollectionPlan[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 更新自訂方案項目
     *
     * @param Collectio 自訂方案項目內容
     */
    updateCollectio(
        Collectio: CollectionPlan
        ): Observable<CollectionPlan> {
        let url = '/api/Collection';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.put<CollectionPlan>(
            url,
			
            Collectio
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 建立新自訂方案項目
     *
     * @param Collectio 自訂方案項目內容
     */
    createCollectio(
        Collectio: CollectionPlan
        ): Observable<CollectionPlan> {
        let url = '/api/Collection';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<CollectionPlan>(
            url,
			
            Collectio
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 刪除指定自訂方案項目
     *
     * @param name 自訂方案項目名稱
     */
    deleteCollectio(
        name?: string
        ): Observable<any> {
        let url = '/api/Collection';
        const queryList = [];

        if (name !== null && name !== undefined) {
            queryList.push('name=' + encodeURIComponent(name.toString()));
        }
            window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.delete<any>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 取得指定自訂方案項目
     *
     * @param name 自訂方案項目名稱
     */
    getCollectio(
        name: string
        ): Observable<CollectionPlan> {
        let url = '/api/Collection/{name}';

        url = url.replace('{name}', (name).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<CollectionPlan>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    }