import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config } from '../config';
import { CollectionPlan, CollectionModel, ItemModel, Contract, User, ContractEvent, NoticeInfo, Document, FileInfo, NoticeRecord, PlanModel, ProjectModel, LoginInfo } from '../models';
import clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class FileService {
    constructor(private http: HttpClient) {}


    /**
     * 取得檔案列表
     *
     */
    listFiles(        ): Observable<FileInfo[]> {
        let url = '/api/File';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<FileInfo[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 上傳檔案
     *
     * @param files 
     */
    uploadFile(
        files?: File[]
        ): Observable<FileInfo[]> {
        let url = '/api/File';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        const formData = new FormData();

        for(var item of files){
            formData.append('files', item);
        }
                return this.http.post<FileInfo[]>(
            url,
            formData,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 刪除檔案
     *
     * @param filename 
     */
    deleteFile(
        filename?: string
        ): Observable<any> {
        let url = '/api/File';
        const queryList = [];

        if (filename !== null && filename !== undefined) {
            queryList.push('filename=' + encodeURIComponent(filename.toString()));
        }
            window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.delete<any>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    }