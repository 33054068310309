import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { Config } from './config';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /** 取得列表分頁結果 */
  getAdvertisementList(enable?, keyword?, offset?, limit?): Observable<any> {
    const url = this.commonService.getQuery('/api/DataManage/Advertisement', [
      { property: 'enable', value: enable },
      { property: 'keyword', value: keyword },
      { property: 'offset', value: offset },
      { property: 'limit', value: limit },
    ]);

    return this.http.get(url, Config.defaultOptions);
  }

  /** 取得指定實例 */
  getAdvertisement(id): Observable<any> {
    return this.http.get(
      `/api/DataManage/Advertisement/${id}`,
      Config.defaultOptions,
    );
  }

  /** 建立實例 */
  create(data, file): Observable<any> {
    const formData = new FormData();
    formData.append('Title', data.title);
    formData.append('Description', data.description);
    formData.append('Enable', data.enable);
    formData.append('File', file);
    return this.http.post(
      '/api/DataManage/Advertisement',
      formData,
      Config.defaultOptions,
    );
  }

  /** 更新實例 */
  update(data, file?): Observable<any> {
    const formData = new FormData();
    formData.append('Title', data.title);
    formData.append('Description', data.description);
    formData.append('Enable', data.enable);
    if (file) {
      formData.append('File', file);
    }
    return this.http.put(
      `/api/DataManage/Advertisement/${data.id}`,
      formData,
      Config.defaultOptions,
    );
  }

  /** 刪除指定實例 */
  delete(id) {
    return this.http.delete(
      `/api/DataManage/Advertisement/${id}`,
      Config.defaultOptions,
    );
  }
}

@Injectable()
export class GetAdvertisementListResolver implements Resolve<any> {
  constructor(private service: AdvertisementService) {}
  resolve() {
    return this.service.getAdvertisementList();
  }
}

@Injectable()
export class GetAdvertisementResolver implements Resolve<any> {
  constructor(private service: AdvertisementService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params.id;
    return this.service.getAdvertisement(id);
  }
}
