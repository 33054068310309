import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { Config } from './config';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CompanyCategoryService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /** 取得列表分頁結果 */
  getCompanyCategoryList(enable?, keyword?, offset?, limit?): Observable<any> {
    const url = this.commonService.getQuery('/api/DataManage/CompanyCategory', [
      { property: 'enable', value: enable },
      { property: 'keyword', value: keyword },
      { property: 'offset', value: offset },
      { property: 'limit', value: limit },
    ]);

    return this.http.get(url, Config.defaultOptions);
  }

  /** 取得指定實例 */
  getCompanyCategory(id): Observable<any> {
    return this.http.get(
      `/api/DataManage/CompanyCategory/${id}`,
      Config.defaultOptions,
    );
  }

  /** 建立實例 */
  create(data): Observable<any> {
    return this.http.post(
      '/api/DataManage/CompanyCategory',
      data,
      Config.defaultOptions,
    );
  }

  /** 更新實例 */
  update(data): Observable<any> {
    return this.http.put(
      `/api/DataManage/CompanyCategory/${data.id}`,
      data,
      Config.defaultOptions,
    );
  }

  /** 刪除指定實例 */
  delete(id) {
    return this.http.delete(
      `/api/DataManage/CompanyCategory/${id}`,
      Config.defaultOptions,
    );
  }
}
@Injectable()
export class GetCompanyCategoryListResolver implements Resolve<any> {
  constructor(private service: CompanyCategoryService) {}
  resolve() {
    return this.service.getCompanyCategoryList();
  }
}

@Injectable()
export class GetCompanyCategoryResolver implements Resolve<any> {
  constructor(private service: CompanyCategoryService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params.id;
    return this.service.getCompanyCategory(id);
  }
}
