import { CollectionPlan } from './collectionPlan';

import { CollectionModel } from './collectionModel';

import { ItemModel } from './itemModel';

import { User } from './user';

import { ContractEvent } from './contractEvent';

import { NoticeInfo } from './noticeInfo';

import { Document } from './document';

import { FileInfo } from './fileInfo';

import { NoticeRecord } from './noticeRecord';

import { PlanModel } from './planModel';

import { ProjectModel } from './projectModel';

import { LoginInfo } from './loginInfo';
export class Contract {

    /**
     *
     */
    id? : string;

    /**
     *
     */
    userId? : string;

    /**
     *
     */
    signingTimeYear? : number;

    /**
     *
     */
    signingTimeMonth? : number;

    /**
     *
     */
    signingTimeDay? : number;

    /**
     *
     */
    serviceTimeYear? : number;

    /**
     *
     */
    serviceTimeMonth? : number;

    /**
     *
     */
    serviceTimeDay? : number;

    /**
     *
     */
    order? : string;

    /**
     *
     */
    deceased? : string;

    /**
     *
     */
    sacrificeOffset? : number;

    /**
     * 使用者
     */
    user? : any;
}
