import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config } from '../config';
import { CollectionPlan, CollectionModel, ItemModel, Contract, User, ContractEvent, NoticeInfo, Document, FileInfo, NoticeRecord, PlanModel, ProjectModel, LoginInfo } from '../models';
import clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class NoticeRecordService {
    constructor(private http: HttpClient) {}


    /**
     * 取得通知列表
     *
     */
    getList(        ): Observable<NoticeRecord[]> {
        let url = '/api/NoticeRecord';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<NoticeRecord[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    }