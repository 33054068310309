import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  /** 登入認證並取得存取權杖 */
  login(data) {
    return this.http.post('/api/UserManage/Authorization', data);
  }
}
