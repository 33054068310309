import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeNoImgPipe'
})
export class ChangeNoImgPipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return (!value || value === 'string') ? '/assets/images/noimage.svg' : value;
  }

}
