import { CollectionPlan } from './collectionPlan';

import { CollectionModel } from './collectionModel';

import { ItemModel } from './itemModel';

import { Contract } from './contract';

import { User } from './user';

import { ContractEvent } from './contractEvent';

import { NoticeInfo } from './noticeInfo';

import { FileInfo } from './fileInfo';

import { NoticeRecord } from './noticeRecord';

import { PlanModel } from './planModel';

import { ProjectModel } from './projectModel';

import { LoginInfo } from './loginInfo';
export class Document {

    /**
     * 
     */
    id? : number;

    /**
     * 
     */
    content? : string;

    /**
     * 
     */
    title? : string;
}