import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config } from '../config';
import { CollectionPlan, CollectionModel, ItemModel, Contract, User, ContractEvent, NoticeInfo, Document, FileInfo, NoticeRecord, PlanModel, ProjectModel, LoginInfo } from '../models';
import clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) {}


    /**
     * 取得指定帳號的使用者資料
     *
     * @param account 使用者帳號
     */
    get(
        account: string
        ): Observable<User> {
        let url = '/api/User/{account}';

        url = url.replace('{account}', (account).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<User>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 刪除使用者資料
     *
     * @param account 使用者帳號
     */
    delete(
        account: string
        ): Observable<any> {
        let url = '/api/User/{account}';

        url = url.replace('{account}', (account).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.delete<any>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 取得使用者列表
     *
     * @param keyword 關鍵字
     * @param offset 起始索引
     * @param limit 取得筆數
     */
    getList(
        keyword?: string,

        offset: number=0,

        limit: number=10
        ): Observable<User[]> {
        let url = '/api/User';
        const queryList = [];

        if (keyword !== null && keyword !== undefined) {
            queryList.push('keyword=' + encodeURIComponent(keyword.toString()));
        }
    
        if (offset !== null && offset !== undefined) {
            queryList.push('offset=' + encodeURIComponent(offset.toString()));
        }
    
        if (limit !== null && limit !== undefined) {
            queryList.push('limit=' + encodeURIComponent(limit.toString()));
        }
            window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<User[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 更新使用者資料
     *
     * @param user 使用者資料
     */
    update(
        user: User
        ): Observable<User> {
        let url = '/api/User';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.put<User>(
            url,
			
            user
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 新增使用者資料
     *
     * @param user 
     */
    create(
        user: User
        ): Observable<User> {
        let url = '/api/User';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<User>(
            url,
			
            user
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 取得目前登入管理者
     *
     */
    getCurrentUser(        ): Observable<User> {
        let url = '/api/User/current';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<User>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 變更使用者密碼
     *
     * @param account 
     * @param password 新密碼
     */
    changeUserPassword(
        account: string,

        password: string
        ): Observable<any> {
        let url = '/api/User/{account}/password';

        url = url.replace('{account}', (account).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        tmpOptions.headers['Content-Type'] = 'application/json';
			
        return this.http.put<any>(
            url,
			JSON.stringify(
            password
			),
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 我的訂單合約列表
     *
     * @param hasOrder 是否過濾已經選擇的項目
     */
    myContract(
        hasOrder?: boolean
        ): Observable<Contract[]> {
        let url = '/api/User/current/contract';
        const queryList = [];

        if (hasOrder !== null && hasOrder !== undefined) {
            queryList.push('hasOrder=' + encodeURIComponent(hasOrder.toString()));
        }
            window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<Contract[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 更新我的合約
     *
     * @param contractId 合約ID
     * @param order 訂單內容
     */
    setContractOrder(
        contractId: string,

        order: string
        ): Observable<Contract> {
        let url = '/api/User/current/contract/{contractId}';

        url = url.replace('{contractId}', (contractId).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        tmpOptions.headers['Content-Type'] = 'application/json';
			
        return this.http.put<Contract>(
            url,
			JSON.stringify(
            order
			),
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 登入認證並取得存取權杖
     *
     * @param loginInfo 登入資訊
     */
    login(
        loginInfo: LoginInfo
        ): Observable<string> {
        let url = '/api/User/login';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<string>(
            url,
			
            loginInfo
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    }