import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileService } from 'src/app/api-sdk';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-file-box',
  templateUrl: './file-box.component.html',
  styleUrls: ['./file-box.component.scss']
})
export class FileBoxComponent implements OnInit {
  @Input() fileList = [];
  @Output() close = new EventEmitter();

  selected = null;

  constructor(
    private fileService: FileService
  ) { }

  ngOnInit() {
  }

  upload(event) {
    this.fileService.uploadFile(event.target.files)
      .subscribe(res => {
        this.fileList = [
          ...this.fileList,
          ...res
        ];
      });
  }

  selectFile(index) {
    this.selected = index;
  }

  closePopupWithFile() {
    const file = this.fileList.find((res, index) => this.selected === index);
    if (file) {
      this.close.emit(file);
    }
  }

  closePopup() {
    this.close.emit();
  }

  delete(e, name, index) {
    e.stopPropagation();
    Swal.fire({
      title: '確定要刪除?',
      text: '您將無法回復刪除的項目',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then(result => {
      if (result.value) {
        this.fileService.deleteFile(name)
        .subscribe(() => {
          this.fileList.splice(index, 1);
        });
      }
    });
  }

}
