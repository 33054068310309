import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-optional-program-editor',
  templateUrl: './optional-program-editor.component.html',
  styleUrls: ['./optional-program-editor.component.scss'],
})
export class OptionalProgramEditorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
