import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  public transform(value: string, type: string = 'html'): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html': return this._sanitizer.bypassSecurityTrustHtml(value ? value.replace(/\n/g, '<br />') : value);
      case 'style': return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'background-image': return this._sanitizer.bypassSecurityTrustStyle(
        `background-image:url('${value}')`
      );
      case 'border-color': return this._sanitizer.bypassSecurityTrustStyle(
        `border-color:${value}`
      );
      case 'youtu-background-image':
        const url = value.split('/');
        return this._sanitizer.bypassSecurityTrustStyle(
          `url('https://i.ytimg.com/vi/${url[url.length - 1]}/hqdefault.jpg')`
        ); // https://i.ytimg.com/vi/HSOtku1j600/hqdefault.jpg
      case 'script': return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url': return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl': return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
