import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-collection-item',
  templateUrl: './collection-item.component.html',
  styleUrls: ['./collection-item.component.scss']
})
export class CollectionItemComponent implements OnInit {

  @Input() choiceArray = [];
  @Input() project;

  @Output() outputChoiceItem = new EventEmitter<any>();
  @Input() isPopup = false;
  itemChoice;
  constructor(
  ) {
  }

  ngOnInit() {
    this.itemChoice = this.project.items[0];
    console.log(this.isPopup);
  }

  changeItem(item) {
    this.itemChoice = item;
  }

  choiceItem(item) {
    if (this.isPopup) {
      return;
    }
    item.choice = !item.choice;
    if (item.choice) {
      const tempItem = JSON.parse(JSON.stringify(item));
      tempItem.items = [this.itemChoice];
      this.choiceArray.push(tempItem);
    } else {
      const indexOf = this.choiceArray.findIndex(arrayItem => arrayItem.name === item.name);
      this.choiceArray.splice(indexOf, 1);
    }
    this.outputChoiceItem.emit(this.choiceArray);
  }
}
