import { Component, OnInit } from '@angular/core';
import { Config } from './api-sdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Funeral-Backstage';

  ngOnInit() {
    if (sessionStorage.getItem('token')) {
      Config.defaultOptions = {
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
      };
    }
  }
}
