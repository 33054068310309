import { Component, OnInit, Input, Inject } from '@angular/core';
import { PlanModel, UserService } from 'src/app/api-sdk';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-plan-item',
  templateUrl: './plan-item.component.html',
  styleUrls: ['./plan-item.component.scss']
})
export class PlanItemComponent implements OnInit {

  @Input() project: PlanModel;
  @Input() contractModel;
  isPopup = false;

  constructor(
    public dialogRef: MatDialogRef<PlanItemComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data) {
      this.isPopup = true;
      this.project = data;
    }
  }

  ngOnInit() {
  }

  delete() {
    Swal.fire({
      title: '確定要刪除?',
      text: '您將無法回復刪除的項目',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then(result => {
      if (result.value) {
        this.dialogRef.close(true);
      }
    });
  }
}
