import { CollectionPlan } from './collectionPlan';

import { CollectionModel } from './collectionModel';

import { ItemModel } from './itemModel';

import { Contract } from './contract';

import { User } from './user';

import { NoticeInfo } from './noticeInfo';

import { Document } from './document';

import { FileInfo } from './fileInfo';

import { NoticeRecord } from './noticeRecord';

import { PlanModel } from './planModel';

import { ProjectModel } from './projectModel';

import { LoginInfo } from './loginInfo';
export class ContractEvent {

    /**
     *
     */
    id? : string;

    /**
     *
     */
    contractId? : string;

    /**
     *
     */
    timeYear? : number;

    /**
     *
     */
    timeMonth? : number;

    /**
     *
     */
    timeDay? : number;

    /**
     *
     */
    deceased? : string;

    /**
     *
     */
    time? : string;

    /**
     *
     */
    userId? : string;

    /**
     *
     */
    title? : string;

    /**
     *
     */
    type? : number;

    /**
     * 使用者
     */
    user? : any;

    contract?: Contract;
}
