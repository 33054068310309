import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  getQuery(url: string, array: Array<{ property: string; value: any }>) {
    let result = url;
    const queryList = [];
    array.map(res => {
      if (res.value !== null && res.value !== undefined) {
        if (
          typeof res.value === 'string' ||
          typeof res.value === 'number' ||
          typeof res.value === 'boolean'
        ) {
          queryList.push(
            `${res.property}=` + encodeURIComponent(res.value.toString()),
          );
        } else if (typeof res.value === 'object') {
          for (const value of res.value) {
            if (value) {
              queryList.push(
                `${res.property}=` + encodeURIComponent(value.toString()),
              );
            }
          }
        }
      }
    });

    if (queryList.length) {
      result += `?${queryList.join('&')}`;
    }

    return result;
  }
}
