import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Config } from '../config';
import { CollectionPlan, CollectionModel, ItemModel, Contract, User, ContractEvent, NoticeInfo, Document, FileInfo, NoticeRecord, PlanModel, ProjectModel, LoginInfo } from '../models';
import clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
    constructor(private http: HttpClient) {}


    /**
     * 取得Document列表
     *
     */
    listDocuments(        ): Observable<Document[]> {
        let url = '/api/Document';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<Document[]>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 更新Document
     *
     * @param document 
     */
    updateDocument(
        document: Document
        ): Observable<Document> {
        let url = '/api/Document';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.put<Document>(
            url,
			
            document
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 建立新的Document
     *
     * @param document 
     */
    createDocument(
        document: Document
        ): Observable<Document> {
        let url = '/api/Document';
        const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        let tmpOptions = clone(Config.defaultOptions);


        return this.http.post<Document>(
            url,
			
            document
			,
            tmpOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 取得指定Document
     *
     * @param id 
     */
    getDocument(
        id: number
        ): Observable<Document> {
        let url = '/api/Document/{id}';

        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.get<Document>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    
    /**
     * 刪除指定Document
     *
     * @param id 
     */
    deleteDocument(
        id: number
        ): Observable<any> {
        let url = '/api/Document/{id}';

        url = url.replace('{id}', (id).toString());
            const queryList = [];
        window['lastRequestTime'] = new Date().getTime();
        if(queryList.length > 0){
            url += '?'+ queryList.join('&');
        }

        return this.http.delete<any>(
            url,
            Config.defaultOptions
        ).pipe(
          catchError((error: any, caught: Observable<any>) => {
            Config.onError.next({error: error, caught: caught});
            return null;
          })
        );
    }
    }