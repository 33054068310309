import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from './services/common.service';
import {
  SliderService,
  AuthService,
  LoaderInterceptorService,
  LoaderService,
  AdvertisementService,
  CompanyCategoryService,
  CompanyService,
  FCMService,
} from './services';
import { LoaderComponent } from './components/loader/loader.component';
import { SearchComponent } from './components/search/search.component';
import { ProgramEditorComponent } from './components/program-editor/program-editor.component';
import { OptionalProgramEditorComponent } from './components/optional-program-editor/optional-program-editor.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { FileBoxComponent } from './components/file-box/file-box.component';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { CollectionItemComponent } from './components/collection-item/collection-item.component';
import { ChangeNoImgPipePipe } from './pipes/change-no-img-pipe.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { PlanItemComponent } from './components/plan-item/plan-item.component';

const MATERIAL = [
  MatButtonModule,
  MatIconModule,
  MatSlideToggleModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule,
  MatTableModule,
  MatDialogModule
];

const SERVICES = [
  LoaderInterceptorService,
  LoaderService,
  CommonService,
  SliderService,
  AuthService,
  AdvertisementService,
  CompanyCategoryService,
  CompanyService,
  FCMService,
];

const COMPONENTS = [
  LoaderComponent,
  SearchComponent,
  ProgramEditorComponent,
  OptionalProgramEditorComponent,
  FileBoxComponent,
  CollectionItemComponent,
  PlanItemComponent
];

const MODULES = [CKEditorModule, NgxFullCalendarModule];

@NgModule({
  declarations: [COMPONENTS, ChangeNoImgPipePipe, SafePipe],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MATERIAL, MODULES],
  exports: [MATERIAL, COMPONENTS, MODULES],
  entryComponents: [COMPONENTS],
  providers: [
    SERVICES,
    { provide: MAT_DIALOG_DATA, useValue: null }
  ],
})
export class ThemeModule { }
