import { CollectionPlan } from './collectionPlan';

import { CollectionModel } from './collectionModel';

import { ItemModel } from './itemModel';

import { Contract } from './contract';

import { User } from './user';

import { ContractEvent } from './contractEvent';

import { NoticeInfo } from './noticeInfo';

import { Document } from './document';

import { FileInfo } from './fileInfo';

import { PlanModel } from './planModel';

import { ProjectModel } from './projectModel';

import { LoginInfo } from './loginInfo';
export class NoticeRecord {

    /**
     *
     */
    id? : number;

    /**
     *
     */
    title? : string;

    /**
     *
     */
    message? : string;

    /**
     *
     */
    sendTime? : number;

    /**
     *
     */
    readTime? : number;

    /**
     *
     */
    userId? : string;

    /**
     *
     */
    eventTime? : number;

    /**
     *
     */
    contractEventId? : string;

    /**
     *
     */
    contractId? : string;

    /**
     * 合約事件
     */
    contractEvent? : any;
}
