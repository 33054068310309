import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Config } from './config';

@Injectable({
  providedIn: 'root',
})
export class FCMService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /** FCM推播 */
  postFCM(data): Observable<any> {
    return this.http.post(
      '/api/NotificationManage/FCM',
      data,
      Config.defaultOptions,
    );
  }
}
